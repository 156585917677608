import './public-path';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import loadable from '@loadable/component';
import ErrorBoundary from './ErrorBoundary';

function init() {
  const nodes = document.querySelectorAll('[data-react]');
  for(let i=0; i<nodes.length; i++) {
    const el = nodes[i];
    let {react: component, ...props} = el.dataset;
    const Component = loadable(() => import(
      /* webpackInclude: /apps\/[^\/]+\/index.js$/ */
      /* webpackChunkName: "[request]" */
      `./apps/${component}`
    ));

    if(component in window && window[component].props){
      props = {...props, ...window[component].props};
    }

    ReactDOM.render(
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    , el);
  }
}

smoothscroll.polyfill();

if(document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}
